import { AppPost } from '@/utils/request';

// 点位分页列表查询
export function queryStationList(data) {
  return AppPost('/queryStationList', data);
}

// 保存点位
export function saveStation(data) {
  return AppPost('/saveStation', data);
}

// 删除点位
export function deleteStation(stationId) {
  return AppPost('/deleteStation', { stationId });
}

// 切换点位状态
export function toggleStationStatus(data) {
  return AppPost('/toggleStationStatus', data);
}

// 查询检查项列表
export function getExameListByStationId(stationId) {
  return AppPost('/getExameListByStationId', { stationId });
}

// 删除检查项
export function deleteExame(exameId) {
  return AppPost('/deleteExame', { exameId });
}

// 切换检查项状态
export function toggleExameStatus(data) {
  return AppPost('/toggleExameStatus', data);
}

// 保存测评项目
export function saveExame(data) {
  return AppPost('/saveExame', data);
}

export function getExame(exameId) {
  return AppPost('/getExame', { exameId });
}
