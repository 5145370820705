const { REACT_APP_AUTH_TOKEN, REACT_APP_URL_TYPE } = process.env;

const getUrl = () => {
  switch (REACT_APP_URL_TYPE) {
    case 'dev':
      return {
        baseUrl: 'http://127.0.0.1:7001/',
        uploadUrl: 'http://127.0.0.1:7001/upload-file',
      };
    case 'qa':
      return {
        baseUrl: 'https://api-test.ccwy2018.com/',
        uploadUrl: 'https://api-test.ccwy2018.com/upload-file',
      };
    case 'prod':
      return {
        baseUrl: 'https://api.ccwy2018.com/',
        uploadUrl: 'https://api.ccwy2018.com/upload-file',
      };
    default:
      return {
        baseUrl: 'https://api.ccwy2018.com/',
        uploadUrl: 'https://api.ccwy2018.com/upload-file',
      };
  }
};

const config = {
  token: REACT_APP_AUTH_TOKEN,
  ...getUrl(),
};

export default config;
