import { AppPost } from '@/utils/request';

// 点位分页列表查询
export function queryUserList(data) {
  return AppPost('/queryUserList', data);
}

// 保存用户信息
export function saveUser(data) {
  return AppPost('/saveUser', data);
}

// 删除用户
export function deleteUser(userId) {
  return AppPost('/deleteUser', { userId });
}

// 切换用户状态
export function toggleUserStatus(data) {
  return AppPost('/toggleUserStatus', data);
}

// 导入用户
export function exportUser(data) {
  return AppPost('/exportUser', data);
}
