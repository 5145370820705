import { AppPost } from '@/utils/request';

// 企业页列表查询
export function queryCompanyList(data) {
  return AppPost('/queryCompanyList', data);
}

// 根据企业Id查询企业信息
export function getCompanyInfo(companyId) {
  return AppPost(`/getCompanyInfo`, { companyId });
}

// 根据id删除企业
export function delCompanyById(companyId) {
  return AppPost('/delCompanyById', { companyId });
}

// 保存企业
export function saveCompany(data) {
  return AppPost('/saveCompany', data);
}

// 查询企业字典项
export function getCompanyDict() {
  return AppPost('/getCompanyDict');
}
