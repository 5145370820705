import Loading from '@/components/loading';
import {
  CompassOutlined,
  DashboardOutlined,
  RocketOutlined,
  SettingOutlined,
  TeamOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import loadable from '@loadable/component';
import React from 'react';

const PersonCenter = loadable(() => import('@/pages/person-center'), {
  fallback: <Loading />,
});

const DashBoard = loadable(() => import('@/pages/dashboard'), {
  fallback: <Loading />,
});

const StationMgt = loadable(() => import('@/pages/station-mgt'), {
  fallback: <Loading />,
});

const ExameList = loadable(() => import('@/pages/exame-list'), {
  fallback: <Loading />,
});

const ContentEditor = loadable(() => import('@/pages/content-editor'), {
  fallback: <Loading />,
});

const UserList = loadable(() => import('@/pages/user-list'), {
  fallback: <Loading />,
});

const CompanyMgt = loadable(() => import('@/pages/company-mgt'), {
  fallback: <Loading />,
});

const routerMain = [
  {
    path: '/admin/dashboard',
    name: '首页',
    component: DashBoard,
    icon: DashboardOutlined,
    exact: true,
    noMenu: false,
  },
  {
    path: '/admin/user',
    name: '用户管理',
    icon: ToolOutlined,
    noMenu: false,
    child: [
      {
        path: '/admin/user/list',
        name: '用户列表',
        component: UserList,
        icon: TeamOutlined,
        exact: true,
        noMenu: false,
      },
    ],
  },
  {
    path: '/admin/mgt',
    name: '内容管理',
    icon: RocketOutlined,
    noMenu: false,
    child: [
      {
        path: '/admin/mgt/station-mgt',
        name: '点位管理',
        component: StationMgt,
        icon: SettingOutlined,
        exact: true,
        noMenu: false,
      },
      {
        path: '/admin/exame-list/:stationId',
        name: '测评项目管理',
        component: ExameList,
        icon: SettingOutlined,
        exact: true,
        noMenu: true,
      },
      {
        path: '/admin/content-edit/:exameId?',
        name: '内容编辑',
        component: ContentEditor,
        icon: SettingOutlined,
        noMenu: true,
      },
    ],
  },
  {
    path: '/admin/my',
    name: '个人中心',
    component: PersonCenter,
    icon: CompassOutlined,
    exact: true,
    noMenu: true,
  },
  {
    path: '/admin/company',
    name: '企业管理',
    icon: CompassOutlined,
    exact: true,
    noMenu: false,
    child: [
      {
        path: '/admin/company/mgt',
        name: '企业列表',
        component: CompanyMgt,
        exact: true,
        noMenu: false,
      },
    ],
  },
];

export default routerMain;
