import { AppGet, AppPost } from '@/utils/request';

// 登陆
export function checkLogin(data) {
  return AppPost('/login', data);
}

// 根据token获取用户信息
export function getUserInfoByToken() {
  return AppGet('/getAccountByToken');
}
